import { ILinkInfo, INavbarLink } from "@amelia-genesis/api";

export const headerLinks: INavbarLink[] = [
  {
    link: {
      name: "About Us",
      url: "/about-us",
    }
  },
  {
    link: {
      name: "Services",
      url: "/services",
    }
  },
  // {
  //   link: {
  //     name: "Our Experts",
  //     url: "/our-experts",
  //   }
  // },
  {
    link: {
      name: "Contact Us",
      url: "/contact-us",
    }
  }
];

export const getProposalLink: ILinkInfo = {
  name: "Free Fertility Quiz",
  url: "/quiz",
};