"use client";

import Image from "next/image";

import heroImg from "@/../public/images/main/amelia-main-page.jpg";
import { useCommonTranslations } from "@amelia-genesis/common";
import BorderLine from "../templete/Common/BorderLine";
import { getProposalLink } from "../../constants/common";
import { PrimaryButton } from "../buttons";
import React from "react";

const HeroBanner = () => {
  const { tCommon } = useCommonTranslations();

  return (
    <>
      <div className="">
        <div className="relative min-h-[80vh] flex justify-center items-center">
          <Image
            src={heroImg}
            alt="Hero Image"
            className="h-full w-full top-0 left-0 object-cover absolute"

            // data-aos="fade-up"
            // data-aos-delay="100"
            // data-aos-duration="600"
          />

          <div className="container mx-auto text-center relative z-10">
            <div className="w-full mx-auto mb-[20px] sm:mb-[30px] md:mb-[40px]">
              <h1 className="max-w-[1020px] mx-auto text-center text-white text-[26px] md:text-[30px] lg:text-[40px] xl:text-[50px] leading-[30px] md:leading-[40px] lg:leading-[50px] font-semibold"
              >
                {tCommon("heroBannerTitle")}
              </h1>

              {/* Hero Image */}
            </div>
            <div className="max-w-[1020px] w-full mx-auto">
              <p
                className="text-[16px] md:text-[20px] font-medium mb-[20px] sm:mb-[30px] md:mb-[40px] text-white"
              >
                {tCommon("heroBannerSubtitle")}
              </p>

              <div className="mx-auto w-max">
                <PrimaryButton
                  label={"What about your health?"}
                  href={getProposalLink.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BorderLine/>
    </>
  );
};

export default HeroBanner;
