
import { FC, ReactNode } from "react";
import { useStableEffect } from "@amelia-genesis/library";
import {NoPrefetchLink, NoPrefetchLinkProps} from "./NoPrefetchLink";

interface SmoothScrollLinkProps extends NoPrefetchLinkProps {
  children: ReactNode;
}

export const SmoothScrollLink: FC<SmoothScrollLinkProps> = ({ children, ...props }) => {
  useStableEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <NoPrefetchLink {...props}>
      {children}
    </NoPrefetchLink>
  );
};
