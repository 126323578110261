import { FC } from "react";
import { IconDefinition, FontAwesomeIcon } from "@amelia-genesis/common/utilities/icons/fontawesome"
import { classNames } from "@amelia-genesis/library";

interface IIInfoCardProps {
  title: string;
  description: string;
  icon: IconDefinition;
  sectionNumber: number;
  className?: string;
  iconClassName?: string;
}

const InfoCard: FC<IIInfoCardProps> = ({ title, description, icon, sectionNumber, className, iconClassName }) => {
  const initialDelay = 100;
  const delay = initialDelay * sectionNumber;

  return (
    <div
      data-aos="fade-up"
      data-aos-delay={delay}
      data-aos-duration="600"
      data-aos-once="true"
      className={classNames(
        "text-center",
        className
      )}
    >
      <div className={classNames(
        "flex justify-center items-center bg-lightGrey w-[70px] h-[70px] rounded-full mb-[20px] mx-auto",
        iconClassName
      )}>
        <FontAwesomeIcon icon={icon} className="text-primary h-[30px]" />
      </div>

      <h3 className="text-[22px] text-black font-semibold mb-[10px]">
        {title}
      </h3>
      <p>
        {description}
      </p>
    </div>
  )
};

export default InfoCard;