import React, { FC } from "react";
import { NoPrefetchLink } from "@amelia-genesis/common/components";
import { LinkProps } from "next/link";
import { IconDefinition, FontAwesomeIcon } from "@amelia-genesis/common/utilities/icons/fontawesome";
import { classNames } from "@amelia-genesis/library";

interface IPrimaryButtonProps {
  label?: string;
  href?: string;
  className?: string;
  icon?: IconDefinition;
  linkProps?: LinkProps;
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  children?: React.ReactNode;
  iconClassName?: string;
  labelClassName?: string;
}

const PrimaryButton: FC<IPrimaryButtonProps> = (props) => {
  const {
    label,
    href,
    className,
    icon,
    buttonProps,
    linkProps,
    children,
    iconClassName,
    labelClassName,
  } = props;

  if (href) {
    return (
      <NoPrefetchLink
        {...linkProps}
        href={href}
        className={classNames(
          "bg-black text-white text-[14px] font-medium flex items-center uppercase rounded-full py-[15px] px-[30px] transition duration-500 ease-in-out hover:bg-primary",
          className || ""
        )}
      >
        {label}
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className="inline-block relative pl-2 text-white w-4 h-4"
          />
        )}
      </NoPrefetchLink>
    )
  }

  return (
    <button {...buttonProps} className={classNames(
      "bg-black flex items-center rounded-full py-[15px] px-[30px] transition duration-500 ease-in-out hover:bg-primary disabled:bg-black/30",
      className || ""
    )}>
      {label && (
        <span className={classNames(
          "uppercase text-white text-[14px] font-medium font-outfit",
          labelClassName || ""
        )}>{label}</span>
      )}
      {children}
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={classNames(
            "inline-block relative pl-2 text-white w-4 h-4",
            iconClassName || ""
          )}
        />
      )}
    </button>
  )
};

export default PrimaryButton;