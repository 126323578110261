"use client";

import Image from "next/image";

import assetImg from "../../../../public/images/main/OurProcess.png";
import { useCommonTranslations } from "../../index";
import { ourProcessSteps } from "../../constants/home";
import OurProcessWidgetSteps from "../ourProcess/OurProcessWidgetSteps";
import React from "react";
import { getProposalLink } from "../../constants/common";
import { PrimaryButton } from "../buttons";

const OurProcess = () => {
  const { tCommon } = useCommonTranslations();

  return (
    <>
      <div className="py-[50px] md:py-[60px] lg:py-[80px] xl:py-[100px]">
        <div className="container mx-auto">
          {/* Section Header */}
          <div className="max-w-[648px] mx-auto text-center mb-[30px] md:mb-[60px]">
            <h2 className="text-black font-semibold text-[25px] md:text-[30px] lg:text-[32px] xl:text-[36px] leading-[1.2]">
              {tCommon("ourProcessLabel")}
            </h2>
          </div>

          <div className="grid items-center gap-[25px] grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <div 
              className="space-y-[30px] lg:space-y-[40px]"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="600"
              data-aos-once="true"
            >
              {ourProcessSteps.map((step, index) => (
                <OurProcessWidgetSteps key={index} {...step}/>
              ))}
            </div>

            <div 
              className="relative"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <Image src={assetImg} alt="asset" />

              <div className="absolute bottom-[-30px] right-0 left-0 text-center">
                <PrimaryButton
                  className="w-max mx-auto"
                  label={getProposalLink.name}
                  href={getProposalLink.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProcess;
