import React, { FC } from "react";
import { faChevronUp, FontAwesomeIcon } from "@amelia-genesis/common/utilities/icons/fontawesome";
import { NoPrefetchLink } from "@amelia-genesis/common/components";

interface IOurProcessLink {
  label: string;
  url: string;
}

export interface IOurProcessWidgetStepsProps {
  title: string;
  description: string;
  step: number;
  link?: IOurProcessLink;
}

const OurProcessWidgetSteps: FC<IOurProcessWidgetStepsProps> = ({ step, title, description, link }) => {
  return (
    <div className="flex space-x-[20px] rtl:space-x-reverse">
      <div className="text-[75px] text-[#E3E3E3] italic font-bold leading-none">
        {step}
      </div>
      <div className="pr-[0] xl:pr-[160px] xl:rtl:pr-0 xl:rtl:pl-[160px]">
        <div className="flex items-center flex-wrap gap-y-2 justify-between mb-[10px]">
          <h3 className="text-black text-[20px] md:text-[22px] font-semibold">
            {title}
          </h3>
          {link && (
            <NoPrefetchLink
              href={link.url}
              className="bg-black text-white text-[14px] font-medium inline-block uppercase rounded-full py-[8px] px-[16px] transition duration-500 ease-in-out hover:bg-primary"
            >
              {link.label}{" "}
              <FontAwesomeIcon icon={faChevronUp} className="inline-block relative text-white h-[16px] rotate-90"/>
            </NoPrefetchLink>
          )}
        </div>
        <p>
          {description}
        </p>
      </div>
    </div>
  )
};

export default OurProcessWidgetSteps;