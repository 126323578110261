"use client";

import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import { ITestimonialSlide } from "@amelia-genesis/api";
import { testimonialsSlider } from "@amelia-genesis/common/constants/home";
import { useCommonTranslations } from "@amelia-genesis/common";

const TestimonialsSlider = () => {
  const { tCommon } = useCommonTranslations();

  return (
    <>
      <div className="py-[50px] md:py-[60px] lg:py-[80px] xl:py-[100px]">
        <div className="container mx-auto">
          <div className="grid items-center gap-[25px] grid-cols-1">
            <div className="testimonials-slider-content">
              <h4 className="text-black uppercase text-[15px] md:text-[17px] font-medium mb-[15px]">
                {tCommon("testimonialsSectionLabel")}
              </h4>

              <Swiper
                autoplay={{
                  delay: 5000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="testimonial-slider"
              >
                {testimonialsSlider.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <h4 className="bg-lightGrey/70 rounded-[100px] py-[7px] px-[23px] font-medium text-black text-[16px] md:text-[18px] max-w-max mb-[20px] text-center">
                      {slide.label}
                    </h4>

                    <q className="italic text-[18px] font-medium color-[#4C4C4C]">
                      {slide.description}
                    </q>

                    <div className="mt-[40px] flex items-center space-x-[15px] rtl:space-x-reverse">
                      {slide.author.image ? (
                        <div className="shrink-0">
                          <Image
                            src={slide.author.image}
                            alt="user"
                            className="rounded-[50px] w-[46px] h-[46px] border-[1px] border-[#000] object-cover"
                          />
                        </div>
                      ) : (
                        <div className="shrink-0">
                          <span className="inline-block w-[46px] h-[46px] overflow-hidden rounded-full bg-gray-100">
                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                              <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                          </span>
                        </div>
                      )}


                      <div>
                        <h5 className="text-black font-medium text-[18px]">
                          {slide.author.name}
                        </h5>
                        <p>{slide.author.title}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            
            {/* Reviews */}

            {/*<Reviews />*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsSlider;
