export { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export type { IconDefinition } from "@fortawesome/fontawesome-common-types";

// regular icons
export { faCheck } from "@chaika-tech/pro-regular-svg-icons/faCheck";
export { faVial } from "@chaika-tech/pro-regular-svg-icons/faVial";
export { faVials } from "@chaika-tech/pro-regular-svg-icons/faVials";
export { faDna } from "@chaika-tech/pro-regular-svg-icons/faDna";
export { faMicroscope } from "@chaika-tech/pro-regular-svg-icons/faMicroscope";

// light icons
export { faChevronUp } from "@chaika-tech/pro-light-svg-icons/faChevronUp";
export { faChevronRight } from "@chaika-tech/pro-light-svg-icons/faChevronRight";

// solid icons
export { faLightbulb } from "@chaika-tech/pro-solid-svg-icons/faLightbulb";
export { faHandshakeAngle } from "@chaika-tech/pro-solid-svg-icons/faHandshakeAngle";
export { faChartUser } from "@chaika-tech/pro-solid-svg-icons/faChartUser";
export { faUserDoctorMessage } from "@chaika-tech/pro-solid-svg-icons/faUserDoctorMessage";
export { faUserDoctor } from "@chaika-tech/pro-solid-svg-icons/faUserDoctor";
export { faCalendarCheck } from "@chaika-tech/pro-solid-svg-icons/faCalendarCheck";
export { faClipboardList } from "@chaika-tech/pro-solid-svg-icons/faClipboardList";
export { faEnvelope } from "@chaika-tech/pro-solid-svg-icons/faEnvelope";

// sharp-solid icons
export { faHeartbeat } from "@chaika-tech/sharp-solid-svg-icons/faHeartbeat";
export { faHandHoldingHand } from "@chaika-tech/sharp-solid-svg-icons/faHandHoldingHand";
export { faLocationDot } from "@chaika-tech/sharp-solid-svg-icons/faLocationDot";
export { faInfoCircle } from "@chaika-tech/sharp-solid-svg-icons/faInfoCircle";
export { faClock } from "@chaika-tech/sharp-solid-svg-icons/faClock";
