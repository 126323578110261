import { FC } from "react";
import { ICustomBlockWithIcon } from "@amelia-genesis/api";
import { FontAwesomeIcon } from "@amelia-genesis/common/utilities/icons/fontawesome";

const AwardWinningItem: FC<ICustomBlockWithIcon> = ({ title, description, icon }) => {
  return (
    <div
      className="lg:pl-[40px] pt-4 lg:rtl:pl-[0] lg:rtl:pr-[40px] text-center xl:text-left xl:rtl:text-right relative"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-duration="600"
      data-aos-once="true"
    >
      <div className="bg-[#FEE] w-[84px] h-[84px] mx-auto xl:mx-0  leading-[84px] rounded-full flex items-center justify-center mb-[20px]">
        <FontAwesomeIcon
          icon={icon}
          className="text-primary w-[45px] h-[45px]"
        />
      </div>

      <h3 className="text-black font-semibold text-[25px] md:text-[32px] lg:text-[36px]">
        {title}
      </h3>

      <p className="text-[#4C4C4C] font-semibold text-[16px] md:text-[18px] mb-[10px] md:mb-[20px]">
        {description}
      </p>
    </div>
  )
};

export default AwardWinningItem;