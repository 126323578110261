"use client";

import { useCommonTranslations } from "../../index";
import { NoPrefetchLink } from "../index";
import InfoCard from "../InfoCard";
import { whatWeDoInfoBlocks } from "../../constants/home";
import { FontAwesomeIcon, faChevronUp } from "../../utilities/icons/fontawesome";

const WhatWeDo = () => {
  const { tCommon } = useCommonTranslations();

  return (
    <>
      <div className="py-[50px] md:py-[60px] lg:py-[80px] xl:py-[100px]">
        <div className="container mx-auto">
          <div className="grid gap-5 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <h4 className="text-black uppercase text-[15px] md:text-[17px] font-medium mb-[15px]">
                {tCommon("whatWeDoLabel")}
              </h4>

              <h2 className="text-black font-semibold text-[25px] md:text-[30px] lg:text-[32px] xl:text-[36px] leading-[1.2]">
                {tCommon("whatWeDoTitle")}
              </h2>

              <NoPrefetchLink
                href="/about-us/"
                className="flex items-center gap-1 font-medium text-black uppercase transition duration-500 ease-in-out inline-block hover:text-[#EF4335] mt-[20px] lg:mt-[40px] xl:mt-[60px]"
              >
                <span>{tCommon("whatWeDoLinkLabel")}{" "}</span>
                <FontAwesomeIcon icon={faChevronUp} className="inline-block relative text-primary h-[16px] rotate-90"/>
              </NoPrefetchLink>
            </div>

            <div className="lg:col-span-2 xl:pl-[130px]">
              <div 
                className="mb-[30px] md:mb-[40px] lg:mb-[40px] xl:mb-[60px]"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="600"
                data-aos-once="true"
              >
                <p>
                  {tCommon("whatWeDoDescription")}
                </p>
              </div>

              <div className="grid gap-6 sm:gap-10 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                {whatWeDoInfoBlocks.map((infoBlock, index) => (
                  <InfoCard
                    key={index}
                    title={infoBlock.title}
                    description={infoBlock.description}
                    icon={infoBlock.icon}
                    sectionNumber={index + 1}
                    className={index == whatWeDoInfoBlocks.length - 1 ? "col-span-full mx-auto max-w-[351px]" : ""}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
