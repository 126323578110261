"use client";

import Image from "next/image";

import trophy from "/public/images/trophy.png";
import AwardWinningItem from "./AwardWinningItem";
import { awardWinningAgency } from "@amelia-genesis/common/constants/home";
import { useCommonTranslations } from "@amelia-genesis/common";

const AwardWinningAgency = () => {
  const { tCommon } = useCommonTranslations();

  return (
    <>
      <div className="container mx-auto mb-[-190px]">
        <div className="bg-[#FAF4F4] relative rounded-[20px] p-[30px] sm:p-[40px]">
          <div className="grid gap-[25px] grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4">
            <div 
              className="bg-[#FEE] text-center rounded-[20px] py-[50px] px-[30px]"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <Image src={trophy} alt="trophy" className="mx-auto" />

              <h3 className="text-black font-medium text-[18px]">
                {tCommon("awardWinningAgencyTitle")}
              </h3>
            </div>

            {awardWinningAgency.map((item, index) => (
              <AwardWinningItem key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AwardWinningAgency;
