import { FC, CSSProperties, SyntheticEvent } from "react";
import Image from "next/image";

import { IBuddyImage } from "@amelia-genesis/api";
import { themeBreakpoints, shimmer, toBase64 } from "@amelia-genesis/common";

declare type SafeNumber = number | `${number}`;

export interface IBuddyImageSize {
  default: number; // Mandatory, always last
  sm?: number; // Optional
  md?: number; // Optional
  lg?: number; // Optional
  xl?: number; // Optional
}

interface IBuddyImageProps {
  image: IBuddyImage | null | undefined;
  size: IBuddyImageSize;
  quality?: SafeNumber | undefined;
  priority?: boolean;
  unoptimized?: boolean;
  style?: CSSProperties;
  className?: string;
  onLoadingComplete?: () => void;
  hidePlaceholder?: boolean;
  onLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
}

export const buildImageSizeString = (size: IBuddyImageSize | number): string => {
  // Check if size is a number
  if(typeof size === 'number') {
    return `${size}vw`;
  }

  let sizeString = '';

  if(size.sm !== undefined) {
    sizeString += `(max-width: ${themeBreakpoints.sm}px) ${size.sm}vw, `;
  }

  if(size.md !== undefined) {
    sizeString += `(max-width: ${themeBreakpoints.md}px) ${size.md}vw, `;
  }

  if(size.lg !== undefined) {
    sizeString += `(max-width: ${themeBreakpoints.lg}px) ${size.lg}vw, `;
  }

  if(size.xl !== undefined) {
    sizeString += `(max-width: ${themeBreakpoints.xl}px) ${size.xl}vw, `;
  }

  // Add default property last, without max-width
  if(size.default !== undefined) {
    sizeString += `${size.default}vw`;
  }

  return sizeString;
}

const BuddyImage: FC<IBuddyImageProps> = ({
  image,
  style,
  className,
  size,
  quality,
  priority,
  unoptimized,
  onLoadingComplete,
  hidePlaceholder,
  onLoad
}) => {

  if (!image) {
    return null;
  }

  // replace http:// to https:// in url
  // noinspection HttpUrlsUsage
  const url = image.url.replace('http://', 'https://');

  const width = image.width || image.height || 640;
  const height = image.height || image.width || 480;

  return (
    <Image
      key={url}
      src={{
        src: url,
        width: width,
        height: height,
      }}
      onLoad={onLoad}
      alt={image.title}
      unoptimized={unoptimized || image.external}
      placeholder={hidePlaceholder ? 'empty' : `data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`}
      quality={quality}
      priority={priority}
      title={image.title}
      style={style}
      className={className}
      sizes={buildImageSizeString(size)}
      onLoadingComplete={onLoadingComplete}
    />
  );
};

export default BuddyImage;
