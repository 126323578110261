import { ICustomBlockWithIcon, ITestimonialSlide } from "@amelia-genesis/api";
import { faCheck } from "@amelia-genesis/common/utilities/icons/fontawesome";
import { IOurProcessWidgetStepsProps } from "@amelia-genesis/common/components/ourProcess/OurProcessWidgetSteps";

import userImg2 from "@/../public/images/user2.png";
import userImg3 from "@/../public/images/user3.png";
import couple from "@/../public/images/couple.jpg";

export const whatWeDoInfoBlocks: ICustomBlockWithIcon[] = [
  {
    title: "Fertility Preservation",
    description: "Early personalized recommendations based on user preferences and needs",
    icon: faCheck,
  },
  {
    title: "Data-driven solution",
    description: "Insights from successful journeys of others",
    icon: faCheck,
  },
  {
    title: "We have it all in one app",
    description: "Ovulation testing, hormone testing, clinic and doctor selection, genetic screening, and treatment",
    icon: faCheck,
  }
];

export const ourProcessSteps: IOurProcessWidgetStepsProps[] = [
  {
    step: 1,
    title: "Take a free fertility quiz",
    description: "Take our free, confidential fertility quiz to determine your risk of future fertility issues and understand your unique reproductive profile.",
    link: {
      label: "take a FREE quiz",
      url: "/quiz",
    },
  },
  {
    step: 2,
    title: "Comprehensive Fertility Evaluation",
    description: "Gain deep insights into your fertility health through our comprehensive evaluation, including hormone and genetic tests, semen analysis, and ultrasounds.",
  },
  {
    step: 3,
    title: "Expert Consultation & Support",
    description: "Schedule a free 15-minute consultation with one of our fertility experts to discuss your results and receive personalized recommendations, with ongoing support available whenever you need it.",
  },
];

export const awardWinningAgency: ICustomBlockWithIcon[] = [
  {
    title: "1 in 3",
    description: "women struggling to have a child of their own in mid-30th",
    icon: faCheck,
  },
  {
    title: "97% of women ",
    description: "wished they had more information about their own fertility and reproductive aging",
    icon: faCheck,
  },
  {
    title: "81% of women ",
    description: "say emotional stress is equal to or greater than financial stress during fertility treatment",
    icon: faCheck,
  }
];

export const testimonialsSlider: ITestimonialSlide[] = [
  {
    label: "Future Family",
    description: "I’m 35 and focused on my career, but I know I want to have children someday. AmeliaGenesis gave me the peace of mind I needed by providing a comprehensive fertility assessment and personalized recommendations for preserving my fertility. I feel empowered and in control of my future family-building options.",
    author: {
      name: "Sarah",
      title: "Marketing Manager",
      image: userImg2,
    }
  },
  {
    label: "Male Fertility",
    description: "As a 42-year-old single man, I never thought much about my fertility until I found AmeliaGenesis. Their male fertility evaluation and guidance on lifestyle factors that can impact sperm health were eye-opening. I’m now making informed decisions about my reproductive future and feel more confident about my options.",
    author: {
      name: "Michael",
      title: "Software Engineer",
      image: userImg3,
    }
  },
  {
    label: "Family Planing, Egg Freezing",
    description: "My husband and I are in our early 30s and want to have children in the future, but our family doctor was reluctant to test our fertility. AmeliaGenesis provided the comprehensive evaluation we were looking for, and their experts identified lifestyle factors that were impacting our fertility. With their guidance, we’ve made changes to improve our reproductive health and are now considering egg freezing to preserve our options.",
    author: {
      name: "Amanda and David",
      title: "Accountant and Lawyer",
      image: couple,
    }
  }
]