import React, { FC } from "react";
import { classNames } from "@amelia-genesis/library";
import { INavbarLink } from "@amelia-genesis/api";
import { NoPrefetchLink } from "@amelia-genesis/common/components";

interface INavbarLinkProps {
  data: INavbarLink;
  currentRoute: string;
}

const NavbarLink: FC<INavbarLinkProps> = ({ data, currentRoute }) => {
  const { link, dropdown } = data;

  return (
    <li className="xl:mx-[10px] 2xl:mx-[18px] py-[10px] lg:py-[15px] xl:py-[35px] 2xl:py-[41.5px] relative group last:mr-0 first:ml-0">
      <NoPrefetchLink
        href={link.url}
        className={classNames(
          "text-black uppercase text-[14px] font-medium transition-all hover:text-primary",
          dropdown ? "dropdown-toggle" : ""
        )}
      >
        {link.name}
      </NoPrefetchLink>

      {dropdown && (
        <ul className="absolute bg-white border left-0 rtl:right-0 w-[270px] top-[100%] shadow-dropdown py-[15px] opacity-0 invisible transition-all group-hover:opacity-100 group-hover:visible">
          {dropdown.map((item, index) => (
            <li className="py-[7px] px-[22px]" key={index}>
              <NoPrefetchLink
                href={item.url}
                className={`block font-medium text-[15px] transition-all hover:text-[#EF4335] ${
                  currentRoute === item.url} ? "text-primary" : "text-black"
                }`}
              >
                {item.name}
              </NoPrefetchLink>
            </li>
          ))}
        </ul>

      )}
    </li>
  )
};

export default NavbarLink;
