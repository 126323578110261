import Navbar from "@amelia-genesis/common/components/header/Navbar";
import HeroBanner from "@amelia-genesis/common/components/home/HeroBanner";
import MeetOurExperts from "@amelia-genesis/common/components/home/MeetOurExperts";
import BorderLine from "@amelia-genesis/common/components/templete/Common/BorderLine";
import WhatWeDo from "@amelia-genesis/common/components/home/WhatWeDo";
import OurProcess from "@amelia-genesis/common/components/home/OurProcess";
import AwardWinningAgency from "@amelia-genesis/common/components/awardWinningAgency/AwardWinningAgency";
import TestimonialsSlider from "@amelia-genesis/common/components/templete/Common/TestimonialsSlider";
import Footer from "@amelia-genesis/common/components/templete/Layout/Footer";

export default function Home() {
  return (
    <>
      <div className="gradient-bg">

        <Navbar />

        <HeroBanner />

        <MeetOurExperts />
      </div>

      <BorderLine />

      <WhatWeDo />

      <div className="gradient-bg-three">
        {/*<ServicesCard />*/}

        <OurProcess />

        <AwardWinningAgency />
      </div>

      <div className=" pt-[190px]">
        {/*<SuccessStories />*/}

        {/*<BrandsSlider />*/}
        <TestimonialsSlider />

        {/*<BorderLine />*/}
      </div>


      {/*<Cta />*/}

      {/*<OurBlog />*/}

      <Footer />
    </>
  );
}
