"use client";

import React, { FC, useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";

import logo from "@/../public/images/app/amelia-logo.png";
import { NoPrefetchLink } from "@amelia-genesis/common/components/link";
import { getProposalLink, headerLinks } from "@amelia-genesis/common/constants/common";
import NavbarLink from "./NavbarLink";
import { PrimaryButton } from "@amelia-genesis/common/components/buttons";
import { faChevronRight } from "@amelia-genesis/common/utilities/icons/fontawesome";
import BuddyErrorBoundary from "../errors/BuddyErrorBoundary";

interface INavbarProps {
  isSticky?: boolean;
}

const Navbar: FC<INavbarProps> = ({ isSticky = true }) => {
  const currentRoute = usePathname();

  // Sticky Navbar
  useEffect(() => {
    if (!isSticky) return;

    const navbarElement = document.getElementById("navbar");
    const parentElement = navbarElement?.parentElement;

    if (!navbarElement || !parentElement) return;

    const handleScroll = () => {
      if (window.scrollY > 170) {
        navbarElement.classList.add("isSticky");
        parentElement.style.paddingTop = `${navbarElement.offsetHeight}px`;
      } else {
        navbarElement.classList.remove("isSticky");
        parentElement.style.paddingTop = "0";
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toggle active class
  const [isActive, setActive] = useState<boolean>(false);
  const handleToggleSearchModal = () => {
    setActive(!isActive);
  };

  return (
    <BuddyErrorBoundary>
      <div
        id="navbar"
        className="navbar-area bg-transparent relative z-[2] py-[20px] lg:py-[25px] xl:py-0"
      >
        <div className="container mx-auto">
          <nav
            className={`navbar relative flex flex-wrap ${
              isActive ? "active" : ""
            }`}
          >
            <div className="self-center">
              <NoPrefetchLink href="/">
                <Image src={logo} className="inline" alt="logo" width={255} height={30} />
              </NoPrefetchLink>
            </div>

            {/* Toggle button */}
            <button
              className="navbar-toggler absolute ml-auto right-0 rtl:left-0 rtl:right-auto top-0 xl:hidden"
              type="button"
              onClick={handleToggleSearchModal}
            >
              <span className="burger-menu text-black cursor-pointer leading-none text-[30px]">
                <i className="bx bx-menu"></i>
              </span>
            </button>

            <div className="navbar-collapse flex self-center grow basis-auto">
              <ul className="navbar-nav self-center flex-row xl:pl-16 mr-auto xl:flex">
                {headerLinks.map((link, index) => (
                  <NavbarLink
                    key={index}
                    data={link}
                    currentRoute={currentRoute}
                  />
                ))}
              </ul>

              {/* Other options */}
              <div className="other-options self-center border-t border-[#eeeeee] pt-[20px] xl:pt-[0] pb-[10px] xl:pb-[0] xl:border-none xl:ml-[20px] 2xl:ml-[15px]">
                <PrimaryButton
                  className="min-w-[220px]"
                  icon={faChevronRight}
                  label={getProposalLink.name}
                  href={getProposalLink.url}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </BuddyErrorBoundary>
  );
};

export default Navbar;